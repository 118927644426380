import {
  GET_TRANSACTION_GROWTH,
  GET_TRANSACTION_GROWTH_FAILURE,
  GET_TRANSACTION_GROWTH_SUCCESS,
  GET_TRANSACTION_SUMMARY,
  GET_TRANSACTION_SUMMARY_FAILURE,
  GET_TRANSACTION_SUMMARY_SUCCESS,
} from './types';

const initialState = {
  summary: {},
  growth: {data: [], daySummary: {}},
  isLoading: true,
  error: null,
};

const getTransactionGrowthReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
  case GET_TRANSACTION_GROWTH:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case GET_TRANSACTION_GROWTH_SUCCESS:
    return {
      ...state,
      growth: action.response,
      isLoading: false,
      error: null,
    };
  case GET_TRANSACTION_GROWTH_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default:
    return state;
  }
};


const getTransactionSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_TRANSACTION_SUMMARY:
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  case GET_TRANSACTION_SUMMARY_SUCCESS:
    return {
      ...state,
      summary: action.response,
      isLoading: false,
      error: null,
    };
  case GET_TRANSACTION_SUMMARY_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default: return getTransactionGrowthReducer(state, action);
  }
};

export default getTransactionSummaryReducer;
