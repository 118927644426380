import { decamelizeKeys } from 'humps';
import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import apiErrorHandler from '../../services/apiErrorHandler';
import { uploadDataMessage } from '../../components/Helper'
import ExpenseAPI from '../../services/expenseAPI';
import {
  getExpenseSummarySuccess,
  getExpenseSummaryFailure,
  getRecurringExpenseSummarySuccess,
  getRecurringExpenseSummaryFailure,
  createRecurringExpenseSummaryFailure,
  deleteRecurringExpenseFailure

} from './action';
import {
  GET_EXPENSE_SUMMARY,
  GET_RECURRING_EXPENSE_SUMMARY,
  CREATE_RECURRING_EXPENSE_SUMMARY,
  DELETE_RECURRING_EXPENSE
} from './types';

export const getUserState = (state) => state.auth;

export function* getExpenseSummarySaga(action) {
  try {
    const {
      user: { clientId },
    } = yield select(getUserState);

    const body = {
      clientId,
      ...action.data,
    };
    let data = []
    let clientID = localStorage.getItem("client_id")
    if (clientID && parseFloat(clientID) === body.clientId) {
      let expenseOverview = localStorage.getItem(`${body.span}-${body.period}-expense-overview`)
      let expenseData = localStorage.getItem(`expense-data-${body.span}-${body.period}`)
      if (expenseOverview && expenseData) {
        data = {
          overview: JSON.parse(expenseOverview),
          data: JSON.parse(expenseData)
        }
      } else {
        const response = yield call(ExpenseAPI.getExpenseSummary, decamelizeKeys(body));
        data = getExpenseSummaryData(response, body)
      }
    } else {
      const response = yield call(ExpenseAPI.getExpenseSummary, decamelizeKeys(body));
      data = getExpenseSummaryData(response, body)
    }
    yield put(getExpenseSummarySuccess(data));
  } catch (error) {
    yield put(getExpenseSummaryFailure(uploadDataMessage('Expenses')));
  }
}

export function* watchGetExpenseSummarySaga() {
  yield takeEvery(GET_EXPENSE_SUMMARY, getExpenseSummarySaga);
}

export function* getRecurringExpenseSummarySaga(action) {
  try {
    const {
      user: { clientId },
    } = yield select(getUserState);

    const body = {
      clientId,
      ...action.data,
    };
    let data = []
      const response = yield call(ExpenseAPI.getRecurringExpenseSummary, decamelizeKeys(body));
      data = getRecurringExpenseSummaryData(response, body)
    // }
    yield put(getRecurringExpenseSummarySuccess(data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getRecurringExpenseSummaryFailure(errorMessage));
  }
}

export function* watchGetRecurringExpenseSummarySaga() {
  yield takeEvery(GET_RECURRING_EXPENSE_SUMMARY, getRecurringExpenseSummarySaga);
}

export function* createRecurringExpenseSummarySaga(action) {
  try {
    let body = {
      ...action.data,
    };

    if(!action.data.appliedOn){
      body = { ...body, appliedOn: 0 }
    }
    const response = yield call(ExpenseAPI.createRecurringExpenseSummary, decamelizeKeys(body));
    if (response.status === 201) {
      const stringedQueryValues = localStorage.getItem("query_values")
      const queryValues = JSON.parse(stringedQueryValues)
      localStorage.removeItem(`recurring-expense-data-${queryValues.span}-${queryValues.period}`)
      let data = { span: queryValues.span, period: queryValues.period }
      yield put({ type: GET_RECURRING_EXPENSE_SUMMARY, data })
    }

  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(createRecurringExpenseSummaryFailure(errorMessage));
  }
}

export function* watchcreateRecurringExpenseSummarySaga() {
  yield takeEvery(CREATE_RECURRING_EXPENSE_SUMMARY, createRecurringExpenseSummarySaga);
}

const getExpenseSummaryData = (response, body) => {
  if (response.status === 200) {
    let data = response.data.data;
    let overview = response.data.overview;
    if (data.length > 0) {
      localStorage.setItem("client_id", JSON.stringify(body.clientId))
      localStorage.setItem(`${body.span}-${body.period}-expense-overview`, JSON.stringify(overview))
      localStorage.setItem(`expense-data-${body.span}-${body.period}`, JSON.stringify(data))
      return { data, overview };
    }
  } else {
    return {}
  }
}


const getRecurringExpenseSummaryData = (response, body) => {
  if (response.status === 200) {
    let data = response.data.data;
    if (data.length > 0) {
      return { recurringExpenseData: data };
    }
  } else {
    return {}
  }
}

export function* deleteSummaryData(action) {
  try {

    let body = {
      ...action.data,
    };
    const response = yield call(ExpenseAPI.deleteRecurringExpenseSummary, decamelizeKeys(body));
    if (response.status === 200) {
      const stringedQueryValues = localStorage.getItem("query_values")
      const queryValues = JSON.parse(stringedQueryValues)
      localStorage.removeItem(`recurring-expense-data-${queryValues.span}-${queryValues.period}`)
      let data = { span: queryValues.span, period: queryValues.period }
      yield put({ type: GET_RECURRING_EXPENSE_SUMMARY, data })
    }
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(deleteRecurringExpenseFailure(errorMessage));
  }
}

export function* watchDeleteSummaryData() {
  yield takeEvery(DELETE_RECURRING_EXPENSE, deleteSummaryData);
}