import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class ProfileAPI {
  static async getProfile(profileData) {
    return axios.get(`${baseUrl}/user`, { params: { ...profileData } });
  }

  static async editProfile(profileData) {
    return axios.put(`${baseUrl}/user`, profileData);
  }
}

export default ProfileAPI ;
