import { CREATE_NEW_METRICS, CREATE_NEW_METRICS_SUCCESS, CREATE_NEW_METRICS_FAILURE } from './types'

export const addNewMetrics = (data) => ({
    type: CREATE_NEW_METRICS,
    data,
});

export const addNewMetricsSuccess = (data) => ({
    type: CREATE_NEW_METRICS_SUCCESS,
    data,
});

export const addNewMetricsFailure = (data) => ({
    type: CREATE_NEW_METRICS_FAILURE,
    data,
});