import { decamelizeKeys } from 'humps';
import {
    call,
    put,
    select,
    takeEvery,
} from 'redux-saga/effects';
import apiErrorHandler from '../../services/apiErrorHandler';
import ExpenseAPI from '../../services/expenseAPI';
import { addNewMetricsFailure, addNewMetricsSuccess } from './actions';
import {
    CREATE_NEW_METRICS
} from './types';

export const getUserState = (state) => state.auth;

export function* createNewMetricsSaga(action) {
    try {
        let body = {
            ...action.data,
        };

        if (!action.data.appliedOn) {
            body = { ...body, appliedOn: 0 }
        }
        const response = yield call(ExpenseAPI.createNewMetrics, decamelizeKeys(body));
        if (response.status === 201) {
            yield put(addNewMetricsSuccess(response.data.message));
        } else {
            yield put(addNewMetricsFailure(response.data.message))
        }

    } catch (error) {
        const errorMessage = apiErrorHandler(error);
        yield put(addNewMetricsFailure(errorMessage));
    }
}

export function* watchcreateNewMetricsSaga() {
    yield takeEvery(CREATE_NEW_METRICS, createNewMetricsSaga);
  }