import { all } from 'redux-saga/effects';

import {
  watchNewLoginUser, watchNewRegisterUser, 
} from './auth/saga';
import { watchRegisterClientSaga, watchSelectClientSaga, watchUpdateClientSaga } from './client/saga';
import {
  watchGetCustomerGrowthSaga, watchGetCustomersLTVSaga, 
  // watchGetCustomersRFMDataSaga,
  watchGetCustomersRetentionSaga, watchGetCustomersRFMSaga, 
} from './customers/saga';
import {
  watchcreateRecurringExpenseSummarySaga, watchDeleteSummaryData, watchGetExpenseSummarySaga, watchGetRecurringExpenseSummarySaga, 
} from './Expenses/saga';
import { watchPreviewCSVSaga, watchUploadFileSaga, watchUploadToTableSaga } from './file/saga';
import { watchcreateNewMetricsSaga } from './metrics/saga';
import { watchGetDaysSummarySaga, watchGetPeriodSummarySaga } from './PeriodPicker/saga';
import { watchEditProfileSaga, watchGetProfileSaga } from './Profile/saga';
import { watchGetProfitSummarySaga } from './Profit/saga';
import { watchGetRevenueGrowthSaga } from './revenue/saga';
import { watchGetViewSummarySaga } from './summary/saga';
import { watchGetTransactionGrowthSaga } from './transaction/saga';

export default function* rootSaga() {
  yield all([
    watchNewLoginUser(),
    watchNewRegisterUser(),
    watchUpdateClientSaga(),
    watchSelectClientSaga(),
    watchEditProfileSaga(),
    watchGetProfileSaga(),
    watchGetDaysSummarySaga(),
    watchGetPeriodSummarySaga(),
    watchGetViewSummarySaga(),
    watchGetExpenseSummarySaga(),
    watchGetProfitSummarySaga(),
    watchGetCustomerGrowthSaga(),
    watchGetCustomersLTVSaga(),
    watchGetCustomersRFMSaga(),
    watchGetRevenueGrowthSaga(),
    watchcreateNewMetricsSaga(),
    watchGetCustomersRetentionSaga(),
    watchGetRecurringExpenseSummarySaga(),
    watchDeleteSummaryData(),
    watchcreateRecurringExpenseSummarySaga(),
    watchGetTransactionGrowthSaga(),
    watchUploadFileSaga(),
    watchPreviewCSVSaga(),
    watchUploadToTableSaga(),
    watchRegisterClientSaga(),
  ]);
}
