import {
    GET_EXPENSE_SUMMARY,
    GET_EXPENSE_SUMMARY_FAILURE,
    GET_EXPENSE_SUMMARY_SUCCESS,
    GET_RECURRING_EXPENSE_SUMMARY,
    GET_RECURRING_EXPENSE_SUMMARY_FAILURE,
    GET_RECURRING_EXPENSE_SUMMARY_SUCCESS,
    CREATE_RECURRING_EXPENSE_SUMMARY,
    CREATE_RECURRING_EXPENSE_SUMMARY_FAILURE,
    CREATE_RECURRING_EXPENSE_SUMMARY_SUCCESS,
    DELETE_RECURRING_EXPENSE,
    DELETE_RECURRING_EXPENSE_SUCCESS,
    DELETE_RECURRING_EXPENSE_FAILURE
  } from './types';

  export const getExpenseSummary = (data) => ({
    type: GET_EXPENSE_SUMMARY,
    data,
  });

  export const getExpenseSummarySuccess = (response) => ({
    type: GET_EXPENSE_SUMMARY_SUCCESS,
    response,
  });
  
  export const getExpenseSummaryFailure = (error) => ({
    type: GET_EXPENSE_SUMMARY_FAILURE,
    error,
  });

  export const getRecurringExpenseSummary = (data) => ({
    type: GET_RECURRING_EXPENSE_SUMMARY,
    data,
  });

  export const getRecurringExpenseSummarySuccess = (response) => ({
    type: GET_RECURRING_EXPENSE_SUMMARY_SUCCESS,
    response,
  });
  
  export const getRecurringExpenseSummaryFailure = (error) => ({
    type: GET_RECURRING_EXPENSE_SUMMARY_FAILURE,
    error,
  });

  export const createRecurringExpenseSummary = (data) => ({
    type: CREATE_RECURRING_EXPENSE_SUMMARY,
    data,
  });

  export const createRecurringExpenseSummarySuccess = (response) => ({
    type: CREATE_RECURRING_EXPENSE_SUMMARY_SUCCESS,
    response,
  });
  
  export const createRecurringExpenseSummaryFailure = (error) => ({
    type: CREATE_RECURRING_EXPENSE_SUMMARY_FAILURE,
    error,
  });
  
  export const deleteRecurringExpense = (data) => ({
    type: DELETE_RECURRING_EXPENSE,
    data,
  });

  export const deleteRecurringExpenseSuccess = (response) => ({
    type: DELETE_RECURRING_EXPENSE_SUCCESS,
    response,
  });
  
  export const deleteRecurringExpenseFailure = (error) => ({
    type: DELETE_RECURRING_EXPENSE_FAILURE,
    error,
  });