import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  all, call, put, select,
  takeEvery, takeLatest,
} from 'redux-saga/effects';

import apiErrorHandler from '../../services/apiErrorHandler';
import CustomerAPI from '../../services/customerAPI';
import {
  getCustomersGrowthFailure,
  getCustomersGrowthSuccess, getCustomersLTVFailure,
  getCustomersLTVSuccess, getCustomersRFMFailure,
  getCustomersRFMSuccess, 
  getCustomersRetentionSuccess, getCustomersRetentionFailure
} from './actions';
import { GET_CUSTOMERS_GROWTH, GET_CUSTOMERS_LTV, GET_CUSTOMERS_RFM, GET_CUSTOMERS_RETENTION } from './types';

export const getAuthState = (state) => state.auth;

// GET RFM SAGA
export function* getCustomersRFMSaga(action) {
  try {
    let data = []
    const { user: { clientId } } = yield select(getAuthState);
    let clientID = localStorage.getItem("client_id")
    if(clientID && parseFloat(clientID) === clientId){
      let lowValueCustomerSegment = localStorage.getItem("customer_segment-low-value")
      let midValueCustomerSegment = localStorage.getItem("customer_segment-mid-value")
      let highValueCustomerSegment = localStorage.getItem("customer_segment-high-value")
      if(lowValueCustomerSegment && midValueCustomerSegment && highValueCustomerSegment){
        data = [
            ...(JSON.parse(lowValueCustomerSegment)), 
            ...(JSON.parse(midValueCustomerSegment)),
            ...(JSON.parse(highValueCustomerSegment))
        ]
      } else {
        const lowValueResponse = yield call(CustomerAPI.getCustomersRFM, { client_id: clientId, segment: "low-value" });
        const midValueResponse = yield call(CustomerAPI.getCustomersRFM, { client_id: clientId, segment: "mid-value" });
        const highValueResponse = yield call(CustomerAPI.getCustomersRFM, { client_id: clientId, segment: "high-value" });
        data = getCustomerSegment(lowValueResponse,midValueResponse,highValueResponse,clientId)
      }
    } else {
      const lowValueResponse = yield call(CustomerAPI.getCustomersRFM, { client_id: clientId, segment: "low-value" });
      const midValueResponse = yield call(CustomerAPI.getCustomersRFM, { client_id: clientId, segment: "mid-value" });
      const highValueResponse = yield call(CustomerAPI.getCustomersRFM, { client_id: clientId, segment: "high-value" });
      data = getCustomerSegment(lowValueResponse,midValueResponse,highValueResponse,clientId)
    }

    yield put(getCustomersRFMSuccess(camelizeKeys(data)));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getCustomersRFMFailure(errorMessage));
  }
}


export function* watchGetCustomersRFMSaga() {
  yield takeLatest(GET_CUSTOMERS_RFM, getCustomersRFMSaga);
}

// GET RETENTION DATA
export function* getCustomersRetentionSaga(action) {
  try {
    const { user: { clientId } } = yield select(getAuthState);
    const body = {
      clientId,
      ...action.data,
    };
    let data = {}
    let clientID = localStorage.getItem("client_id")
    if(clientID && parseFloat(clientID) === clientId){
      let specificLoadedData = localStorage.getItem(`customer-retention-${body.span}-${body.period}`)
      let specificOverviewData = localStorage.getItem(`customer-retention-overview-${body.span}-${body.period}`)

      
      if(specificLoadedData){
        data = { data: JSON.parse(specificLoadedData), overview: JSON.parse(specificOverviewData) }
      } else {
        const response = yield call(CustomerAPI.getCustomersRetention, decamelizeKeys(body));
        data = getRetentionSummary(response, body)
      }
    }else {
      const response = yield call(CustomerAPI.getCustomersRetention, decamelizeKeys(body));
      data = getRetentionSummary(response, body)
    }
    yield put(getCustomersRetentionSuccess(camelizeKeys(data)));

  } catch(error){
    const errorMessage = apiErrorHandler(error);
    yield put(getCustomersRetentionFailure(errorMessage));
  }
}

export function* watchGetCustomersRetentionSaga() {
  yield takeEvery(GET_CUSTOMERS_RETENTION, getCustomersRetentionSaga);
}

// GET LTV SAGA
export function* getCustomersLTVSaga(action) {
  try {
    const { user: { clientId } } = yield select(getAuthState);
    const { periods } = action.data;
    let data = []

    let clientID = localStorage.getItem("client_id")
    if(clientID && parseFloat(clientID) === clientId){
      let thirtyDaySegment = localStorage.getItem("30daysCustomerSegment")
      let ninetyDaySegment = localStorage.getItem("90daysCustomerSegment")
      let oneEightyDaySegment = localStorage.getItem("180daysCustomerSegment")
      let threeSixtyDaySegment = localStorage.getItem("365daysCustomerSegment")
      if(thirtyDaySegment && ninetyDaySegment && oneEightyDaySegment && threeSixtyDaySegment){
        data = {
          30: JSON.parse(thirtyDaySegment),
          90: JSON.parse(ninetyDaySegment),
          180: JSON.parse(oneEightyDaySegment),
          365: JSON.parse(threeSixtyDaySegment)
        }
      } else {
        const response = yield all(periods.map((period) => {
          const body = {
            clientId,
            period,
          };
          return call(CustomerAPI.getCustomersLTV, decamelizeKeys(body));
        }));
        data = getSegmentSummary(response, clientId)
      }
    } else {
      const response = yield all(periods.map((period) => {
        const body = {
          clientId,
          period,
        };
        return call(CustomerAPI.getCustomersLTV, decamelizeKeys(body));
      }));
      data = getSegmentSummary(response, clientId)
    }
    yield put(getCustomersLTVSuccess(camelizeKeys(data)));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getCustomersLTVFailure(errorMessage));
  }
}

export function* watchGetCustomersLTVSaga() {
  yield takeEvery(GET_CUSTOMERS_LTV, getCustomersLTVSaga);
}

// GET CUSTOMER GROWTH SAGA
export function* getCustomerGrowthSaga(action) {
  try {
    const { user: { clientId } } = yield select(getAuthState);

    const body = {
      clientId,
      ...action.data,
    };
    let data = {}
    let clientID = localStorage.getItem("client_id")
    if(clientID && parseFloat(clientID) === body.clientId){
      let specificLoadedData = localStorage.getItem(`customer-growth-${body.span}-${body.period}`)
      let sumaryData = localStorage.getItem(`customer-summary-${body.span}-${body.period}`)
      if(specificLoadedData && sumaryData){
        data = {data: JSON.parse(specificLoadedData), overview: JSON.parse(sumaryData)}
      } else {
        const response = yield call(CustomerAPI.getCustomersGrowth, decamelizeKeys(body));
        data = getCustomerSummaryDataPerPeriod(response, body)
      }
    } else {
      const response = yield call(CustomerAPI.getCustomersGrowth, decamelizeKeys(body));
      data = getCustomerSummaryDataPerPeriod(response, body)
    }
    
    yield put(getCustomersGrowthSuccess(camelizeKeys(data)));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getCustomersGrowthFailure(errorMessage));
  }
}

export function* watchGetCustomerGrowthSaga() {
  yield takeEvery(GET_CUSTOMERS_GROWTH, getCustomerGrowthSaga);
}

const getCustomerSegment = (lowValueResponse,midValueResponse,highValueResponse,clientId) => {
  if(lowValueResponse.status === 200 
      && midValueResponse.status === 200 
      && highValueResponse.status === 200){
    localStorage.setItem("client_id", JSON.stringify(clientId))
    localStorage.setItem("customer_segment-low-value",JSON.stringify(lowValueResponse.data.data))
    localStorage.setItem("customer_segment-mid-value",JSON.stringify(midValueResponse.data.data))
    localStorage.setItem("customer_segment-high-value",JSON.stringify(highValueResponse.data.data))
    return [...lowValueResponse.data.data, ...midValueResponse.data.data, ...highValueResponse.data.data];
  } else {
    return []
  }
}

const getSegmentSummary = (response, clientId) => {
  if(response && response.length > 0){
    const responseArrays = response.map((res) => res.data.data);
    const data = {
      30: responseArrays[0],
      90: responseArrays[1],
      180: responseArrays[2],
      365: responseArrays[3],
    };
    localStorage.setItem("client_id", JSON.stringify(clientId))
    localStorage.setItem("30daysCustomerSegment", JSON.stringify(responseArrays[0]))
    localStorage.setItem("90daysCustomerSegment", JSON.stringify(responseArrays[1]))
    localStorage.setItem("180daysCustomerSegment", JSON.stringify(responseArrays[2]))
    localStorage.setItem("365daysCustomerSegment", JSON.stringify(responseArrays[3]))
    return data;
  } else {
    return {
      30: [],
      90: [],
      180: [],
      365: [],
    }
  }
}

const getCustomerSummaryDataPerPeriod = (response, body) => {
  if(response.status === 200){
    let data = response.data;
    localStorage.setItem("client_id", JSON.stringify(body.clientId))
    localStorage.setItem(`customer-growth-${body.span}-${body.period}`, JSON.stringify(data.data))
    localStorage.setItem(`customer-summary-${body.span}-${body.period}`, JSON.stringify(data.overview))
    return { data: data.data, overview: data.overview };   
  } else {
    return { data: [], overview: {} };
  }
}

const getRetentionSummary = (response, body) => {
  if(response.status === 200){
    let data = response.data;
    localStorage.setItem("client_id", JSON.stringify(body.clientId))
    localStorage.setItem(`customer-retention-${body.span}-${body.period}`, JSON.stringify(data.data))
    localStorage.setItem(`customer-retention-overview-${body.span}-${body.period}`, JSON.stringify(data.overview))
    return { data: data.data, overview: data.overview };   
  } else {
    return { data: [], overview: {} };
  }
}

// const getRetentionSummary = (response, clientId) => {
//   if(response && response.length > 0){
//     const responseArrays = response.map((res) => res.data);
//     const data = {
//       30: responseArrays[0],
//       90: responseArrays[1],
//       180: responseArrays[2],
//       365: responseArrays[3],
//     };
//     localStorage.setItem("client_id", JSON.stringify(clientId))
//     localStorage.setItem("30days_Customer_retention", JSON.stringify(responseArrays[0]))
//     localStorage.setItem("90days_Customer_retention", JSON.stringify(responseArrays[1]))
//     localStorage.setItem("180days_Customer_retention", JSON.stringify(responseArrays[2]))
//     localStorage.setItem("365days_Customer_retention", JSON.stringify(responseArrays[3]))
//     return data;
//   } else {
//     return {
//       30: { overview: { avg_spend: 0, avg_retention: 0}, data: [] },
//       90: { overview: { avg_spend: 0, avg_retention: 0}, data: [] },
//       180: { overview: { avg_spend: 0, avg_retention: 0}, data: [] },
//       365: { overview: { avg_spend: 0, avg_retention: 0}, data: [] },
//     }
//   }
// }