import {
  PREVIEW_CSV,
  PREVIEW_CSV_FAILURE,
  PREVIEW_CSV_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_TO_TABLE,
  UPLOAD_TO_TABLE_FAILURE,
  UPLOAD_TO_TABLE_SUCCESS,
} from './types';

export const uploadFile = (data) => ({
  type: UPLOAD_FILE,
  data,
});

export const uploadFileSuccess = (response) => ({
  type: UPLOAD_FILE_SUCCESS,
  response,
});

export const uploadFileFailure = (error) => ({
  type: UPLOAD_FILE_FAILURE,
  error,
});

export const previewCSV = (data) => ({
  type: PREVIEW_CSV,
  data,
});

export const previewCSVSuccess = (response) => ({
  type: PREVIEW_CSV_SUCCESS,
  response,
});

export const previewCSVFailure = (error) => ({
  type: PREVIEW_CSV_FAILURE,
  error,
});

export const uploadToTable = (data) => ({
  type: UPLOAD_TO_TABLE,
  data,
});

export const uploadToTableSuccess = (response) => ({
  type: UPLOAD_TO_TABLE_SUCCESS,
  response,
});

export const uploadToTableFailure = (error) => ({
  type: UPLOAD_TO_TABLE_FAILURE,
  error,
});
