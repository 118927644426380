import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class ClientAPI {
  static async registerClient(data) {
    return axios.post(`${baseUrl}/client`, data);
  }

  static async getClient(data) {
    // data => { email, dashboard_id }
    return axios.get(`${baseUrl}/client`, { params: { ...data } });
  }

  static async getClientDateSpan(data) {
    // data => { client_id }
    return axios.get(`${baseUrl}/datespan`, { params: { ...data } });
  }

  static async selectClient(data) {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
    return axios.post(`${baseUrl}/select_client`, data);
  }

  static async updateClient(data) {
    return axios.put(`${baseUrl}/client`, data);
  }
}

export default ClientAPI;
