import axios from 'axios';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const tool = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = tool;
const middleware = composeEnhancers(applyMiddleware(sagaMiddleware));
const store = createStore(rootReducer, middleware);

// Set token on all axios urls
const token = localStorage.getItem('jwt-token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

sagaMiddleware.run(rootSagas);

export default store;
