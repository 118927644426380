import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  call, put, select,
  takeEvery,
} from 'redux-saga/effects';

import apiErrorHandler from '../../services/apiErrorHandler';
import { uploadDataMessage } from '../../components/Helper'
import RevenueAPI from '../../services/revenueAPI';
import {
  getRevenueGrowthFailure,
  getRevenueGrowthSuccess,
  getRevenueSummarySuccess,
} from './actions';
import { GET_REVENUE_GROWTH } from './types';

export const getAuthState = (state) => state.auth;

// GET REVENUE GROWTH SAGA
export function* getRevenueGrowthSaga(action) {
  try {
    const { user: { clientId } } = yield select(getAuthState);

    const body = {
      clientId,
      ...action.data,
    };
    let data = {}
    let clientID = localStorage.getItem("client_id")
    if(clientID && parseFloat(clientID) === body.clientId){
      let specificLoadedData = localStorage.getItem(`revenue-growth-${body.span}-${body.period}`)
      let sumaryData = localStorage.getItem(`revenue-summary-${body.span}-${body.period}`)
      let sumaryDayData = localStorage.getItem(`revenue-day-summary-${body.span}-${body.period}`)
      let monthlySummary = localStorage.getItem(`monthly-summary-${body.span}-${body.period}`)
      

      if(specificLoadedData && sumaryData && sumaryDayData && monthlySummary){
        data = {
          data: JSON.parse(specificLoadedData), 
          overview: JSON.parse(sumaryData), 
          summary: JSON.parse(sumaryDayData), 
          monthlySummary: JSON.parse(monthlySummary) 
        }
      } else {
        const response = yield call(RevenueAPI.getRevenueGrowth, decamelizeKeys(body));
        data = getRevenueGrowthData(response, body)
      }
    } else {
      const response = yield call(RevenueAPI.getRevenueGrowth, decamelizeKeys(body));
      data = getRevenueGrowthData(response, body)
    }
    console.log(data)
    yield put(getRevenueGrowthSuccess(camelizeKeys({data: data.data, daySummary: data.summary, monthlySummary: data.monthlySummary })));
    yield put(getRevenueSummarySuccess(camelizeKeys(data.overview)));

  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getRevenueGrowthFailure(uploadDataMessage('Revenue')));
  }
}

export function* watchGetRevenueGrowthSaga() {
  yield takeEvery(GET_REVENUE_GROWTH, getRevenueGrowthSaga);
}

const getRevenueGrowthData = (response, body) => {
  if(response.status === 200){
    let data = response.data;
    localStorage.setItem("client_id", JSON.stringify(body.clientId))
    localStorage.setItem(`revenue-growth-${body.span}-${body.period}`, JSON.stringify(data.data))
    localStorage.setItem(`monthly-summary-${body.span}-${body.period}`, JSON.stringify(data.monthly_summary))
    localStorage.setItem(`revenue-summary-${body.span}-${body.period}`, JSON.stringify(data.overview))
    localStorage.setItem(`revenue-day-summary-${body.span}-${body.period}`, JSON.stringify(data.day_summary))
    return { data: data.data, overview: data.overview, summary: data.day_summary, monthlySummary: data.monthly_summary };   
  } else {
    return { data: [], overview: {} };
  }
}
