export const GET_EXPENSE_SUMMARY = 'GET_EXPENSE_SUMMARY';
export const GET_EXPENSE_SUMMARY_SUCCESS = 'GET_EXPENSE_SUMMARY_SUCCESS';
export const GET_EXPENSE_SUMMARY_FAILURE = 'GET_EXPENSE_SUMMARY_FAILURE';
export const GET_RECURRING_EXPENSE_SUMMARY = 'GET_RECURRING_EXPENSE_SUMMARY';
export const GET_RECURRING_EXPENSE_SUMMARY_FAILURE = 'GET_RECURRING_EXPENSE_SUMMARY_FAILURE';
export const GET_RECURRING_EXPENSE_SUMMARY_SUCCESS = 'GET_RECURRING_EXPENSE_SUMMARY_SUCCESS';
export const CREATE_RECURRING_EXPENSE_SUMMARY = 'CREATE_RECURRING_EXPENSE_SUMMARY';
export const CREATE_RECURRING_EXPENSE_SUMMARY_FAILURE = 'CREATE_RECURRING_EXPENSE_SUMMARY_FAILURE';
export const CREATE_RECURRING_EXPENSE_SUMMARY_SUCCESS = 'CREATE_RECURRING_EXPENSE_SUMMARY_SUCCESS';
export const DELETE_RECURRING_EXPENSE = "DELETE_RECURRING_EXPENSE";
export const DELETE_RECURRING_EXPENSE_SUCCESS = "DELETE_RECURRING_EXPENSE_SUCCESS";
export const DELETE_RECURRING_EXPENSE_FAILURE = "DELETE_RECURRING_EXPENSE_FAILURE";