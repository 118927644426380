import {
    GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE,EDIT_PROFILE,
    EDIT_PROFILE_SUCCESS,EDIT_PROFILE_FAILURE
  } from './types';

const initialState = {
    profile: {},
    isLoading: false,
    passLoading: false,
    error: null,
};

const getProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE:
            return {
                ...state,
                isLoading: true,
                error: null,
              };
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.response,
                isLoading: false,
                error: null,
            };
        case GET_PROFILE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default: return state;
    }
}

const editProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_PROFILE:
            return {
                ...state,
                passLoading: true,
                error: null,
              };
        case EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.response,
                passLoading: false,
                error: null,
            };
        case EDIT_PROFILE_FAILURE:
            return {
                ...state,
                passLoading: false,
                error: action.error,
            };
        default: return getProfileReducer(state, action);
    }
}

export default editProfileReducer;