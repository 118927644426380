import {
    GET_PROFIT_SUMMARY,
    GET_PROFIT_SUMMARY_FAILURE,
    GET_PROFIT_SUMMARY_SUCCESS,
  } from './types';
    
  export const getProfitSummary = (data) => ({
    type: GET_PROFIT_SUMMARY,
    data,
  });
  
  export const getProfitSummarySuccess = (response) => ({
    type: GET_PROFIT_SUMMARY_SUCCESS,
    response,
  });
  
  export const getProfitSummaryFailure = (error) => ({
    type: GET_PROFIT_SUMMARY_FAILURE,
    error,
  });