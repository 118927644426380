
import {
  GET_CUSTOMERS_GROWTH, GET_CUSTOMERS_GROWTH_FAILURE,
  GET_CUSTOMERS_GROWTH_SUCCESS, GET_CUSTOMERS_LTV, GET_CUSTOMERS_LTV_FAILURE,
  GET_CUSTOMERS_LTV_SUCCESS, GET_CUSTOMERS_RFM, GET_CUSTOMERS_RFM_FAILURE,
  GET_CUSTOMERS_RFM_SUCCESS,GET_CUSTOMERS_RFM_DATA, GET_CUSTOMERS_RFM_DATA_SUCCESS,
  GET_CUSTOMERS_RETENTION, GET_CUSTOMERS_RETENTION_SUCCESS, GET_CUSTOMERS_RETENTION_FAILURE
} from './types';

// Get RFM view actions
export const getCustomersRFM = (data) => ({
  type: GET_CUSTOMERS_RFM,
  data,
});

export const getCustomersRFMSuccess = (response) => ({
  type: GET_CUSTOMERS_RFM_SUCCESS,
  response,
});

export const getCustomersRFMDataSuccess = (response) => ({
  type: GET_CUSTOMERS_RFM_DATA_SUCCESS,
  response,
});

export const getCustomersRFMFailure = (error) => ({
  type: GET_CUSTOMERS_RFM_FAILURE,
  error,
});

// Get Customer Retention Actions
export const getCustomersRetention = (data) => ({
  type: GET_CUSTOMERS_RETENTION,
  data,
});

export const getCustomersRetentionSuccess = (response) => ({
  type: GET_CUSTOMERS_RETENTION_SUCCESS,
  response,
});

export const getCustomersRetentionFailure = (error) => ({
  type: GET_CUSTOMERS_RETENTION_FAILURE,
  error,
});

// Get Customers LTV actions
export const getCustomersLTV = (data) => ({
  type: GET_CUSTOMERS_LTV,
  data,
});

export const getCustomersLTVSuccess = (response) => ({
  type: GET_CUSTOMERS_LTV_SUCCESS,
  response,
});

export const getCustomersLTVFailure = (error) => ({
  type: GET_CUSTOMERS_LTV_FAILURE,
  error,
});

// Get Customers Growth actions
export const getCustomersGrowth = (data) => ({
  type: GET_CUSTOMERS_GROWTH,
  data,
});

export const getCustomersRFMData = () => ({
  type: GET_CUSTOMERS_RFM_DATA
});

export const getCustomersGrowthSuccess = (response) => ({
  type: GET_CUSTOMERS_GROWTH_SUCCESS,
  response,
});

export const getCustomersGrowthFailure = (error) => ({
  type: GET_CUSTOMERS_GROWTH_FAILURE,
  error,
});
