/* eslint-disable no-use-before-define */
import axios from 'axios';
import { camelizeKeys } from 'humps';
import {
  call, put, takeEvery,
} from 'redux-saga/effects';

import auth from '../../helpers/Environment';
import { Mixpanel } from '../../helpers/MixPanel';
import apiErrorHandler from '../../services/apiErrorHandler';
import UserAPI from '../../services/userAPI';
import { selectClient } from '../client/actions';
import {
  FORGOT_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  NEW_LOGIN_USER,
  NEW_REGISTER_USER,
  REGISTER_USER,
  RESET_PASSWORD,
} from '../constants';
import {
  forgotPasswordError,
  forgotPasswordSuccess,
  loginUserError,
  loginUserSuccess,
  registerUserError,
  registerUserSuccess,
  resetPasswordError,
  resetPasswordSuccess,
} from './actions';

function* newLoginWithEmailPassword({ payload }) {
  const { user: { email, password }, history } = payload;

  try {
    localStorage.clear();
    const response = yield call(UserAPI.newLoginUser, { 
      email, 
      password,
    });
    const { user, access_token: token } = response?.data || {};
    if (token) {
      const loggedInUser = response?.data.user;
      Mixpanel.identify(loggedInUser.user_id);
      Mixpanel.track('Successful login');
      Mixpanel.people.set({
        'Client Id': loggedInUser.client_id,
        'User Id': loggedInUser.user_id,
        Email: loggedInUser.email,
        $first_name: loggedInUser.email,
        $last_name: '',
        // "Phone Number": loggedInUser.phone_no,
      });
      const haveManyClients = response.data.clients.length > 1;
      const currentClient = response.data.clients.find(
        (client) => client.client_id === response.data.user.client_id,
      );
      const currencyValue = currentClient.currency ? currentClient.currency : 'sh';
      localStorage.setItem('currency', currencyValue);
      localStorage.setItem('jwt-token', token);
      localStorage.setItem('dashboard-id', currentClient.dashboard_id);
      localStorage.setItem('user_profile', JSON.stringify(user));
      localStorage.setItem('user_id', user.user_id);
      localStorage.setItem('new_user', false);
      localStorage.setItem('have_many_clients', haveManyClients);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      const userData = camelizeKeys(user);
      console.log(haveManyClients)
      if (haveManyClients) {
        const clientsData = camelizeKeys(response.data.clients);
        localStorage.setItem('clients', JSON.stringify(clientsData));
      } else {
        yield put(selectClient({ clientId: response.data.clients[0].client_id }));
      }
      yield put(loginUserSuccess(userData));
      history.push('/summary');
    } else {
      yield put(loginUserError('Something went wrong during authentication'));
    }
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(loginUserError(errorMessage));
  }
}

export function* watchNewLoginUser() {
  yield takeEvery(NEW_LOGIN_USER, newLoginWithEmailPassword);
}

function* newRegisterWithEmailPassword({ payload }) {
  const { userData: { email, password }, history } = payload;
  
  try {
    localStorage.clear();
    const response = yield call(UserAPI.newCreateUser, { 
      email, 
      password,
    });
    const { user, access_token: token } = response?.data || {};
    if (token) {
      Mixpanel.identify(user.user_id);
      Mixpanel.track('Successful registered');
      Mixpanel.people.set({
        'Client Id': user.client_id,
        'User Id': user.user_id,
        Email: user.email,
        $first_name: user.email,
        $last_name: '',
      });
      const currentClient = response.data.clients 
        ? response.data.clients.find(
          (client) => client.client_id === response.data.user.client_id,
        )
        : response.data.client;
      const currencyValue = currentClient.currency ? currentClient.currency : 'sh';
      localStorage.setItem('currency', currencyValue);
      localStorage.setItem('jwt-token', token);
      localStorage.setItem('dashboard-id', currentClient.dashboard_id);
      localStorage.setItem('user_profile', JSON.stringify(user));
      localStorage.setItem('user_id', user.user_id);
      localStorage.setItem('new_user', true);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      const userData = camelizeKeys(user);
      yield put(selectClient({ clientId: response.data.client.client_id }));
      yield put(registerUserSuccess(userData));
      history.push('/summary');
    } else {
      yield put(registerUserError(response.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchNewRegisterUser() {
  yield takeEvery(NEW_REGISTER_USER, newRegisterWithEmailPassword);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);
  history.push('/');
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('user_id');
  // eslint-disable-next-line no-empty
  } catch (error) { }
}

const forgotPasswordAsync = async (email) => auth
  .sendPasswordResetEmail(email)
  .then((user) => user)
  .catch((error) => error);

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => auth
  .confirmPasswordReset(resetPasswordCode, newPassword)
  .then((user) => user)
  .catch((error) => error);

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword,
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}
