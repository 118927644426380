import {
  GET_CUSTOMERS_GROWTH, GET_CUSTOMERS_GROWTH_FAILURE,
  GET_CUSTOMERS_GROWTH_SUCCESS, GET_CUSTOMERS_LTV, GET_CUSTOMERS_LTV_FAILURE,
  GET_CUSTOMERS_LTV_SUCCESS, GET_CUSTOMERS_RFM, GET_CUSTOMERS_RFM_FAILURE,
  GET_CUSTOMERS_RFM_SUCCESS, GET_CUSTOMERS_RFM_DATA_SUCCESS, GET_CUSTOMERS_RFM_DATA,
  GET_CUSTOMERS_RETENTION, GET_CUSTOMERS_RETENTION_SUCCESS, GET_CUSTOMERS_RETENTION_FAILURE
} from './types';

const initialState = {
  rfm: [],
  retention: {
    data: [],
    overview: null
  },
  // retention: {
  //   30: { overview: { all: { avgSpend: 0, avgRetention: 0 }, highValue: { avgSpend: 0, avgRetention: 0 }, midValue: { avgSpend: 0, avgRetention: 0 } }, data: [] },
  //   90: { overview: { all: { avgSpend: 0, avgRetention: 0 }, highValue: { avgSpend: 0, avgRetention: 0 }, midValue: { avgSpend: 0, avgRetention: 0 } }, data: [] },
  //   180: { overview: { all: { avgSpend: 0, avgRetention: 0 }, highValue: { avgSpend: 0, avgRetention: 0 }, midValue: { avgSpend: 0, avgRetention: 0 } }, data: [] },
  //   365: { overview: { all: { avgSpend: 0, avgRetention: 0 }, highValue: { avgSpend: 0, avgRetention: 0 }, midValue: { avgSpend: 0, avgRetention: 0 } }, data: [] },
  // },
  ltv: {
    30: [],
    90: [],
    180: [],
    365: [],
  },
  rfmData: {},
  growth: [],
  overview: {},
  retentionLoading: false,
  isLoading: false,
  rfmDataLoading: false,
  rfmLoading: false,
  error: null,
};

const getCustomersRFMReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_CUSTOMERS_RFM:
    return {
      ...state,
      rfmLoading: true,
      error: null,
    };

  case GET_CUSTOMERS_RFM_DATA:
    return {
      ...state,
      rfmDataLoading: true,
      error: null,
    };

  case GET_CUSTOMERS_RFM_SUCCESS:
    return {
      ...state,
      rfm: action.response,
      rfmLoading: false,
      error: null,
    };


  case GET_CUSTOMERS_RFM_DATA_SUCCESS:
    return {
      ...state,
      rfmData: action.response,
      rfmDataLoading: false,
      error: null,
    };


  case GET_CUSTOMERS_RFM_FAILURE:
    return {
      ...state,
      rfmLoading: false,
      error: action.error,
    };
  default: return state;
  }
};

const getCustomerRetentionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_RETENTION:
      return {
        ...state,
        retentionLoading: true,
        error: null,
      };
    case GET_CUSTOMERS_RETENTION_SUCCESS:
      return {
        ...state,
        retention: action.response,
        retentionLoading: false,
        error: null,
      };
    case GET_CUSTOMERS_RETENTION_FAILURE:
      return {
        ...state,
        retentionLoading: false,
        error: action.error,
      };
  default: return getCustomersRFMReducer(state, action);
  }
}

const getCustomersLTVReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_CUSTOMERS_LTV:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case GET_CUSTOMERS_LTV_SUCCESS:
    return {
      ...state,
      ltv: action.response,
      isLoading: false,
      error: null,
    };
  case GET_CUSTOMERS_LTV_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default: return getCustomerRetentionReducer(state, action);
  }
};

const getCustomersGrowthReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_CUSTOMERS_GROWTH:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case GET_CUSTOMERS_GROWTH_SUCCESS:
    return {
      ...state,
      growth: action.response.data,
      overview: action.response.overview,
      isLoading: false,
      error: null,
    };
  case GET_CUSTOMERS_GROWTH_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default: return getCustomersLTVReducer(state, action);
  }
};

export default getCustomersGrowthReducer;
