import {
  PREVIEW_CSV,
  PREVIEW_CSV_FAILURE,
  PREVIEW_CSV_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_TO_TABLE,
  UPLOAD_TO_TABLE_FAILURE,
  UPLOAD_TO_TABLE_SUCCESS,
} from './types';

const initialState = {
  dropdownMetaData: [],
  uploadedFile: {},
  previewData: {},
  uploadTable: {},
  isLoading: false,
  isUploading: false,
  error: null,
};

const fileUploadReducer = (state = initialState, action) => {
  switch (action.type) {
  case UPLOAD_FILE:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case UPLOAD_FILE_SUCCESS:
    return {
      ...state,
      isLoading: false,
      dropdownMetaData: action.response.columns,
      uploadedFile: action.response,
      error: null,
    };
  case UPLOAD_FILE_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default: return state;
  }
};

const csvPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
  case PREVIEW_CSV:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case PREVIEW_CSV_SUCCESS:
    return {
      ...state,
      isLoading: false,
      previewData: action.response,
      error: null,
    };
  case PREVIEW_CSV_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default: return fileUploadReducer(state, action);
  }
};

const uploadToTableReducer = (state = initialState, action) => {
  switch (action.type) {
  case UPLOAD_TO_TABLE:
    return {
      ...state,
      isUploading: true,
      uploadTable: {},
      error: null,
    };
  case UPLOAD_TO_TABLE_SUCCESS:
    return {
      ...state,
      isUploading: false,
      uploadTable: action.response,
      error: null,
    };
  case UPLOAD_TO_TABLE_FAILURE:
    return {
      ...state,
      isUploading: false,
      error: action.error,
    };
  default: return csvPreviewReducer(state, action);
  }
};

export default uploadToTableReducer;
