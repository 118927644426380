// GET_CUSTOMERS_RFM
export const GET_CUSTOMERS_RFM = 'GET_CUSTOMERS_RFM';
export const GET_CUSTOMERS_RFM_SUCCESS = 'GET_CUSTOMERS_RFM_SUCCESS';
export const GET_CUSTOMERS_RFM_FAILURE = 'GET_CUSTOMERS_RFM_FAILURE';

// GET_CUSTOMERS_LTV
export const GET_CUSTOMERS_LTV = 'GET_CUSTOMERS_LTV';
export const GET_CUSTOMERS_LTV_SUCCESS = 'GET_CUSTOMERS_LTV_SUCCESS';
export const GET_CUSTOMERS_LTV_FAILURE = 'GET_CUSTOMERS_LTV_FAILURE';

// GET_CUSTOMERS_GROWTH
export const GET_CUSTOMERS_GROWTH = 'GET_CUSTOMERS_GROWTH';
export const GET_CUSTOMERS_GROWTH_SUCCESS = 'GET_CUSTOMERS_GROWTH_SUCCESS';
export const GET_CUSTOMERS_GROWTH_FAILURE = 'GET_CUSTOMERS_GROWTH_FAILURE';

// GET_CUSTOMERS_RFM_DATA
export const GET_CUSTOMERS_RFM_DATA = 'GET_CUSTOMERS_RFM_DATA';
export const GET_CUSTOMERS_RFM_DATA_SUCCESS = 'GET_CUSTOMERS_RFM_DATA_SUCCESS';

// GET_CUSTOMER_RETENTION
export const GET_CUSTOMERS_RETENTION = 'GET_CUSTOMERS_RETENTION';
export const GET_CUSTOMERS_RETENTION_SUCCESS = 'GET_CUSTOMERS_RETENTION_SUCCESS';
export const GET_CUSTOMERS_RETENTION_FAILURE = 'GET_CUSTOMERS_RETENTION_FAILURE';