import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { NotificationManager } from '../../components/common/react-notifications';
import apiErrorHandler from '../../services/apiErrorHandler';
import FileAPI from '../../services/fileAPI';
import {
  previewCSVFailure,
  previewCSVSuccess,
  uploadFileFailure,
  uploadFileSuccess,
  uploadToTableFailure,
  uploadToTableSuccess,
} from './actions';
import { PREVIEW_CSV, UPLOAD_FILE, UPLOAD_TO_TABLE } from './types';

export const getAuthState = (state) => state.auth;

export const getFileState = (state) => state.file;

// UPLOAD FILE (csv)
export function* uploadFileSaga(action) {
  try {
    const { data: { existingFile } } = action;
    // Check if upload is from already uploaded File
    if (!existingFile) {
      const { user: { clientId, userId } } = yield select(getAuthState);
      const bodyFormData = new FormData();
      bodyFormData.set('client_id', clientId);
      bodyFormData.set('user_id', userId);
      bodyFormData.append('file', action.data.file);
      if (action.data.password) {
        bodyFormData.append('password', action.data.password);
      }
      let env_check = process.env.REACT_APP_ENV === 'development';
      if (env_check) {
        yield put(uploadFileSuccess({}));
      } else {
        const response = yield call(FileAPI.uploadFile, bodyFormData);
        yield put(uploadFileSuccess(camelizeKeys(response.data.file)));
      }

      NotificationManager.success('File Uploaded Successfully', 'Upload Message', 3000, null, null, '');
    } else {
      yield put(uploadFileSuccess(action.data.file));
    }
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    NotificationManager.warning(errorMessage, 'Upload Error', 3000, null, null, '');
    yield put(uploadFileFailure(errorMessage));
  }
}

export function* watchUploadFileSaga() {
  yield takeLatest(UPLOAD_FILE, uploadFileSaga);
}

export function* previewCSVSaga(action) {
  try {
    const { uploadedFile: { fileId } } = yield select(getFileState);
    const response = yield call(FileAPI.previewCSV, decamelizeKeys({ ...action.data, fileId }));
    NotificationManager.success('File Preview Successfully', 'Preview Message', 3000, null, null, '');
    yield put(previewCSVSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    NotificationManager.warning(errorMessage, 'Upload Error', 3000, null, null, '');
    yield put(previewCSVFailure(errorMessage));
  }
}

export function* watchPreviewCSVSaga() {
  yield takeLatest(PREVIEW_CSV, previewCSVSaga);
}

export function* uploadToTableSaga(action) {
  try {
    const { uploadedFile: { fileId } } = yield select(getFileState);
    const response = yield call(FileAPI.uploadToTable,
      decamelizeKeys({ ...action.data, fileId }));
    yield put(uploadToTableSuccess(camelizeKeys(response.data)));
    NotificationManager.success('Data upload successfully', 'Upload Message', 3000, null, null, '');
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    NotificationManager.warning(errorMessage, 'Upload Error', 3000, null, null, '');
    yield put(uploadToTableFailure(errorMessage));
  }
}

export function* watchUploadToTableSaga() {
  yield takeLatest(UPLOAD_TO_TABLE, uploadToTableSaga);
}
