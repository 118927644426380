import {
  EDIT_CLIENT,
  EDIT_CLIENT_FAILURE,
  EDIT_CLIENT_SUCCESS,
  REGISTER_CLIENT,
  REGISTER_CLIENT_FAILURE,
  REGISTER_CLIENT_SUCCESS,
  SELECT_CLIENT,
  SELECT_CLIENT_FAILURE,
  SELECT_CLIENT_SUCCESS,
} from './types';

const initialState = {
  client: {},
  isLoading: false,
  error: null,
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
  case REGISTER_CLIENT:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case REGISTER_CLIENT_SUCCESS:
    return {
      ...state,
      client: action.response,
      isLoading: false,
      error: null,
    };
  case REGISTER_CLIENT_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  case EDIT_CLIENT:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case EDIT_CLIENT_SUCCESS:
    return {
      ...state,
      client: action.response,
      isLoading: false,
      error: null,
    };
  case EDIT_CLIENT_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  case SELECT_CLIENT:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case SELECT_CLIENT_SUCCESS:
    return {
      ...state,
      client: action.response,
      isLoading: false,
      error: null,
    };
  case SELECT_CLIENT_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default:
    return state;
  }
};

export default clientReducer;
