import {
    GET_DAYS_SUMMARY,
    GET_DAYS_SUMMARY_FAILURE,
    GET_DAYS_SUMMARY_SUCCESS,

    GET_PERIOD_SUMMARY,
    GET_PERIOD_SUMMARY_FAILURE,
    GET_PERIOD_SUMMARY_SUCCESS,
  } from './types';
  
  const initialState = {
    span: '30days',
    period: 'weekly',
    isLoading: true,
    error: null,
  };
  
  
  const getDaysReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_DAYS_SUMMARY:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_DAYS_SUMMARY_SUCCESS:
      return {
        ...state,
        span: action.response.data.span,
        isLoading: false,
        error: null,
      };
    case GET_DAYS_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return state;
    }
  };
  
  const getPeriodReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_PERIOD_SUMMARY:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case GET_PERIOD_SUMMARY_SUCCESS:
      return {
        ...state,
        period: action.response.data.period,
        isLoading: false,
        error: null,
      };
    case GET_PERIOD_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: return getDaysReducer(state, action);
    }
  };
  
  export default getPeriodReducer;
  