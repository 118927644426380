import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class RevenueAPI {
  static async getRevenueGrowth(growthData) {
    return axios.get(`${baseUrl}/analytics/revenue/growth`, { params: { ...growthData } });
  }

  static async getRevenueSummary(data) {
    return axios.get(`${baseUrl}/analytics/revenue/summary`, { params: { ...data } });
  }
}

export default RevenueAPI;
