import {
    GET_PROFIT_SUMMARY,
    GET_PROFIT_SUMMARY_FAILURE,
    GET_PROFIT_SUMMARY_SUCCESS,
  } from './types';

  const initialState = {
    profitData: [],
    profitOverview: {},
    isLoading: true,
    error: null,
  };

  const getProfitSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_PROFIT_SUMMARY:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_PROFIT_SUMMARY_SUCCESS:
      console.log(action)
      return {
        ...state,
        profitData: action.response.data,
        profitOverview: action.response.overview,
        isLoading: false,
        error: null,
      };
    case GET_PROFIT_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
    }
  };
  
  export default getProfitSummaryReducer;