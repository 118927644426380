import {
    GET_DAYS_SUMMARY,
    GET_DAYS_SUMMARY_FAILURE,
    GET_DAYS_SUMMARY_SUCCESS,

    GET_PERIOD_SUMMARY,
    GET_PERIOD_SUMMARY_FAILURE,
    GET_PERIOD_SUMMARY_SUCCESS,
  } from './types';
  
  
  // Get DAYS summary actions  sales, transactions, or customers
  export const getDaysSummary = (data) => ({
    type: GET_DAYS_SUMMARY,
    data,
  });
  
  export const getDaysSummarySuccess = (response) => ({
    type: GET_DAYS_SUMMARY_SUCCESS,
    response,
  });
  
  export const getDaysSummaryFailure = (error) => ({
    type: GET_DAYS_SUMMARY_FAILURE,
    error,
  });
  
//   pERIOD picker
export const getPeriodSummary = (data) => ({
    type: GET_PERIOD_SUMMARY,
    data,
  });
  
  export const getPeriodSummarySuccess = (response) => ({
    type: GET_PERIOD_SUMMARY_SUCCESS,
    response,
  });
  
  export const getPeriodSummaryFailure = (error) => ({
    type: GET_PERIOD_SUMMARY_FAILURE,
    error,
  });
  