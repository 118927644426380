import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class CustomerAPI {
  // RFM stands for the three dimensions:
  // Recency – How recently did the customer purchase?
  // Frequency – How often do they purchase?
  // Monetary Value – How much do they spend?
  static async getCustomersRFM(rfmData) {
    return axios.get(`${baseUrl}/analytics/customer/rfm`, { params: { ...rfmData } });
  }

  static async getCustomersLTV(clvData) {
    return axios.get(`${baseUrl}/analytics/customer/clv`, { params: { ...clvData } });
  }

  static async getCustomersRetention(retentionData) {
    console.log('===>',retentionData)
    return axios.get(`${baseUrl}/analytics/customer/retention`, { params: { ...retentionData } });
  }

  static async getCustomersRFMData(rfmData) {
    return axios.get(`${baseUrl}/analytics/customer/rfm_data`, { params: { ...rfmData } });
  }

  static async getCustomersGrowth(growthData) {
    console.log('===>',growthData)
    return axios.get(`${baseUrl}/analytics/customer/growth`, { params: { ...growthData } });
  }
}

export default CustomerAPI;
