import { decamelizeKeys } from 'humps';
import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import apiErrorHandler from '../../services/apiErrorHandler';
import { uploadDataMessage } from '../../components/Helper'
import SummaryAPi from '../../services/summaryAPI';
import {
  getViewSummaryFailure,
  getViewSummarySuccess,
} from './actions';
import { GET_VIEW_SUMMARY } from './types';

export const getUserState = (state) => state.auth;

// GET VIEW SUMMARY SAGA
export function* getViewSummarySaga(action) {
  try {
    const {
      user: { clientId },
    } = yield select(getUserState);

    const body = {
      clientId,
      ...action.data,
    };
    let data = []
    let clientID = localStorage.getItem("client_id")
    if(clientID && parseFloat(clientID) === body.clientId){
      let specificLoadedData = localStorage.getItem(`${body.span}-${body.period}`)
      let newMetricsSpecificLoadedData = localStorage.getItem(`new-metrics-${body.span}-${body.period}`)
      let analyticsSummary = localStorage.getItem(`analytics-summary-${body.span}-${body.period}`)
      let newMetricsAnalyticsSummary = localStorage.getItem(`analytics-summary-new-metrics-${body.span}-${body.period}`)
      if(specificLoadedData 
          && analyticsSummary
          && newMetricsSpecificLoadedData
          && newMetricsAnalyticsSummary){
        data = { 
          data: JSON.parse(specificLoadedData), 
          overview: JSON.parse(analyticsSummary), 
          newMetricsData: JSON.parse(newMetricsSpecificLoadedData), 
          newMetricsOverview: JSON.parse(newMetricsAnalyticsSummary) }
      } else {
        const response = yield call(SummaryAPi.getViewSummary, decamelizeKeys(body));
        const newMetricsResponse = yield call(SummaryAPi.getViewNewMetrics, decamelizeKeys(body));
        data = getSummaryData(response,newMetricsResponse, body)
      }
    } else {
      const response = yield call(SummaryAPi.getViewSummary, decamelizeKeys(body));
      const newMetricsResponse = yield call(SummaryAPi.getViewNewMetrics, decamelizeKeys(body));
      data = getSummaryData(response,newMetricsResponse, body)
    }
    yield put(getViewSummarySuccess(data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getViewSummaryFailure(uploadDataMessage('Summary')));
  }
}

export function* watchGetViewSummarySaga() {
  yield takeEvery(GET_VIEW_SUMMARY, getViewSummarySaga);
}

const getSummaryData = (response,newMetricsResponse, body) => {
  if(response.status === 200 && newMetricsResponse.status === 200){
    let data = response.data.data;
    let newMetrics = newMetricsResponse.data.data;
    if(data.length > 0){
      localStorage.setItem("client_id", JSON.stringify(body.clientId))
      localStorage.setItem(`${body.span}-${body.period}`, JSON.stringify(data))
      localStorage.setItem(`new-metrics-${body.span}-${body.period}`, JSON.stringify(newMetrics))
      localStorage.setItem(`analytics-summary-${body.span}-${body.period}`, JSON.stringify(response.data.overview))
      localStorage.setItem(`analytics-summary-new-metrics-${body.span}-${body.period}`, JSON.stringify(newMetricsResponse.data.overview))
      return { data: data, overview: response.data.overview, newMetricsData: newMetrics, newMetricsOverview: newMetricsResponse.data.overview };
    }
  } else {
    return {}
  }
}