// UPLOAD CSV
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

// PREVIEW CSV
export const PREVIEW_CSV = 'PREVIEW_CSV';
export const PREVIEW_CSV_SUCCESS = 'PREVIEW_CSV_SUCCESS';
export const PREVIEW_CSV_FAILURE = 'PREVIEW_CSV_FAILURE';

// UPLOAD TO TABLE
export const UPLOAD_TO_TABLE = 'UPLOAD_TO_TABLE';
export const UPLOAD_TO_TABLE_SUCCESS = 'UPLOAD_TO_TABLE_SUCCESS';
export const UPLOAD_TO_TABLE_FAILURE = 'UPLOAD_TO_TABLE_FAILURE';
