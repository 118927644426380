import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  call, put,
  takeEvery,
} from 'redux-saga/effects';

import apiErrorHandler from '../../services/apiErrorHandler';
import ProfileAPI from '../../services/ProfileAPI';
import {
  editUserProfileFailure,
  editUserProfileSuccess,
  getUserProfileFailure,
  getUserProfileSuccess, 
} from './actions';
import { EDIT_PROFILE, GET_PROFILE } from './types';

export const getAuthState = (state) => state.auth;

const getProfileData = (response) => {
  if (response.status === 200) {
    const data = response.data.users[0];
    localStorage.setItem('user_profile', JSON.stringify(data));
    return data;   
  } 
  return {};
};

// GET PROFILE SAGA
export function* getProfileSaga() {
  try {
    const userId = localStorage.getItem('user_id');
    
    let data = {};
    const clientId = localStorage.getItem('dashboard-id');
    if (clientId) {
      const specificLoadedData = localStorage.getItem('user_profile');
      console.log(specificLoadedData);
      if (specificLoadedData) {
        data = JSON.parse(specificLoadedData);
      } else {
        const response = yield call(ProfileAPI.getProfile, decamelizeKeys(userId));
        data = getProfileData(response);
      }
    } else {
      const response = yield call(ProfileAPI.getProfile, decamelizeKeys(userId));
      data = getProfileData(response);
    }
    yield put(getUserProfileSuccess(camelizeKeys(data)));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getUserProfileFailure(errorMessage));
  }
}

export function* watchGetProfileSaga() {
  yield takeEvery(GET_PROFILE, getProfileSaga);
}

// Edit User Profile Saga
export function* editProfileSaga({ data: { user }}) {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) {
      return;
    }
    const body = {
      ...user,
      user_id,
    };
    const response = yield call(ProfileAPI.editProfile, decamelizeKeys(body));
    localStorage.setItem('user_profile', JSON.stringify(response.data.user));
    yield put(editUserProfileSuccess(camelizeKeys(response.data.user)));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(editUserProfileFailure(errorMessage));
  }
}

export function* watchEditProfileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfileSaga);
}
