import { decamelizeKeys } from 'humps';
import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import apiErrorHandler from '../../services/apiErrorHandler';
import { uploadDataMessage } from '../../components/Helper'
import ProfitAPI from '../../services/profitAPI';
import {
    getProfitSummarySuccess,
    getProfitSummaryFailure,
} from './action';
import { GET_PROFIT_SUMMARY } from './types';

export const getUserState = (state) => state.auth;

// GET VIEW SUMMARY SAGA
export function* getProfitSummarySaga(action) {
    try {
      const {
        user: { clientId },
      } = yield select(getUserState);
  
      const body = {
        clientId,
        ...action.data,
      };
      let data = []
      let clientID = localStorage.getItem("client_id")
      if(clientID && parseFloat(clientID) === body.clientId){
        let profitOverview = localStorage.getItem(`${body.span}-${body.period}-profit-overview`)
        let profitData = localStorage.getItem(`profit-data-${body.span}-${body.period}`)
        if(profitOverview && profitData){
          data = { 
            overview: JSON.parse(profitOverview), 
            data: JSON.parse(profitData) }
        } else {
          const response = yield call(ProfitAPI.getProfitSummary, decamelizeKeys(body));
          data = getProfitSummaryData(response,body)
        }
      } else {
        const response = yield call(ProfitAPI.getProfitSummary, decamelizeKeys(body));
        data = getProfitSummaryData(response,body)
      }
      yield put(getProfitSummarySuccess(data));
    } catch (error) {
      const errorMessage = apiErrorHandler(error);
      yield put(getProfitSummaryFailure(uploadDataMessage('Profit')));
    }
  }
  
  export function* watchGetProfitSummarySaga() {
    yield takeEvery(GET_PROFIT_SUMMARY, getProfitSummarySaga);
  }
  
  const getProfitSummaryData = (response,body) => {
    if(response.status === 200){
      let data = response.data.data;
      let overview = response.data.overview;
      if(data.length > 0){
        localStorage.setItem("client_id", JSON.stringify(body.clientId))
        localStorage.setItem(`${body.span}-${body.period}-profit-overview`, JSON.stringify(overview))
        localStorage.setItem(`profit-data-${body.span}-${body.period}`, JSON.stringify(data))
        return { data, overview };
      } 
    } else {
      return {}
    }
  }