import {
  put,
  takeEvery,
} from 'redux-saga/effects';

import apiErrorHandler from '../../services/apiErrorHandler';
import {
    getDaysSummarySuccess,
    getDaysSummaryFailure,
    getPeriodSummarySuccess,
    getPeriodSummaryFailure
} from './actions';
import { GET_DAYS_SUMMARY, GET_PERIOD_SUMMARY } from './types';

// GET DAYS SAGA
export function* getDaysSummarySaga(action) {
  try {
    yield put(getDaysSummarySuccess(action));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getDaysSummaryFailure(errorMessage));
  }
}

export function* watchGetDaysSummarySaga() {
  yield takeEvery(GET_DAYS_SUMMARY, getDaysSummarySaga);
}

// GET PERIOD SAGA
export function* getPeriodSummarySaga(action) {

    try {
      yield put(getPeriodSummarySuccess(action));
    } catch (error) {
      const errorMessage = apiErrorHandler(error);
      yield put(getPeriodSummaryFailure(errorMessage));
    }
  }
  
  export function* watchGetPeriodSummarySaga() {
    yield takeEvery(GET_PERIOD_SUMMARY, getPeriodSummarySaga);
  }

