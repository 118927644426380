import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class SummaryAPI {
  static async getViewSummary(data) {
    return axios.get(`${baseUrl}/analytics/summary`, { params: { ...data } });
  }
  static async getViewNewMetrics(data) {
    return axios.get(`${baseUrl}/analytics/newmetrics`, { params: { ...data } });
  }
}

export default SummaryAPI;
