// REGISTER_CLIENT
export const REGISTER_CLIENT = 'REGISTER_CLIENT';
export const REGISTER_CLIENT_SUCCESS = 'REGISTER_CLIENT_SUCCESS';
export const REGISTER_CLIENT_FAILURE = 'REGISTER_CLIENT_FAILURE';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const SELECT_CLIENT_SUCCESS = 'SELECT_CLIENT_SUCCESS';
export const SELECT_CLIENT_FAILURE = 'SELECT_CLIENT_FAILURE';
