import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class ExpenseAPI {
  static async getExpenseSummary(data) {
    return axios.get(`${baseUrl}/analytics/expense`, { params: { ...data } });
  }
  static async getRecurringExpenseSummary(data) {
    return axios.get(`${baseUrl}/data/recurringexpense`, { params: { ...data } });
  }
  static async createRecurringExpenseSummary(data) {
    return axios.post(`${baseUrl}data/recurringexpense`, {...data });
  }
  static async deleteRecurringExpenseSummary(data) {
    return axios.delete(`${baseUrl}data/recurringexpense`, { params: { ...data } });
  }
  static async createNewMetrics(data) {
    return axios.post(`${baseUrl}data/individual`, {...data });
  }
}

export default ExpenseAPI;