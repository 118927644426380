import { combineReducers } from 'redux';

import auth from './auth/reducer';
import client from './client/reducer';
import customer from './customers/reducer';
import profile from './Profile/reducer'
import profit from './Profit/reducer'
import file from './file/reducer';
import menu from './menu/reducer';
import period from './PeriodPicker/reducer';
import expense from './Expenses/reducer';
import revenue from './revenue/reducer';
import settings from './settings/reducer';
import summary from './summary/reducer';
import transaction from './transaction/reducer';
import newMetrics from './metrics/reducer'

const reducers = combineReducers({
  menu,
  settings,
  auth,
  profit,
  summary,
  customer,
  revenue,
  expense,
  newMetrics,
  transaction,
  file,
  client,
  profile,
  period
});

export default reducers;
