import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { NotificationManager } from '../../components/common/react-notifications';
import apiErrorHandler from '../../services/apiErrorHandler';
import ClientAPI from '../../services/clientAPI';
import { newLoginUser } from '../auth/actions';
import {
  registerClientFailure,
  registerClientSuccess,
  selectClientFailure,
  selectClientSuccess,
  updateClientFailure,
  updateClientSuccess, 
} from './actions';
import { EDIT_CLIENT, REGISTER_CLIENT, SELECT_CLIENT } from './types';

// REGISTER CLIENT SAGA
export function* registerClientSaga(action) {
  try {
    const { history, clientData } = action.data;
    const { password, email, dashboardId } = clientData;
    const loginData = { password, email, dashboard: dashboardId };
    const response = yield call(ClientAPI.registerClient, decamelizeKeys(clientData));
    const { client } = response.data;
    yield put(registerClientSuccess(camelizeKeys(client)));
    NotificationManager.success('You\'ve Successfully registered.', 'Registration', 5000, null, null, '');
    yield put(newLoginUser({ user: loginData, history }));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    NotificationManager.warning(errorMessage, 'Registration', 3000, null, null, '');
    yield put(registerClientFailure(errorMessage));
  }
}

export function* watchRegisterClientSaga() {
  yield takeLatest(REGISTER_CLIENT, registerClientSaga);
}

// SELECT CLIENT SAGA
export function* selectClientSaga(action) {
  try {
    const { data } = action;
    const response = yield call(ClientAPI.selectClient, decamelizeKeys(data));
    console.log(response)
    const { client, access_token: token } = response?.data;
    console.log(client)
    localStorage.setItem('jwt-token', token);
    localStorage.setItem('dashboard-id', client.dashboard_id);
    localStorage.setItem('client', JSON.stringify(client));
    yield put(selectClientSuccess(camelizeKeys(client)));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    console.log(errorMessage)

    yield put(selectClientFailure(errorMessage));
  }
}

export function* watchSelectClientSaga() {
  yield takeLatest(SELECT_CLIENT, selectClientSaga);
}

// EDIT CLIENT SAGA
export function* updateClientSaga(action) {
  try {
    const { data } = action;
    const response = yield call(ClientAPI.updateClient, decamelizeKeys(data));
    const { client } = response.data;
    localStorage.setItem('dashboard-id', client.dashboard_id);
    localStorage.setItem('client', JSON.stringify(client));
    yield put(updateClientSuccess(camelizeKeys(client)));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(updateClientFailure(errorMessage));
  }
}

export function* watchUpdateClientSaga() {
  yield takeLatest(EDIT_CLIENT, updateClientSaga);
}
