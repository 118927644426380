import { camelizeKeys, decamelizeKeys } from 'humps';
import {
  call, put, select,
  takeEvery,
} from 'redux-saga/effects';

import apiErrorHandler from '../../services/apiErrorHandler';
import { uploadDataMessage } from '../../components/Helper'
import TransactionAPI from '../../services/transactionAPI';
import {
  getTransactionGrowthFailure,
  getTransactionGrowthSuccess,
  getTransactionSummarySuccess,
} from './actions';
import { GET_TRANSACTION_GROWTH } from './types';

export const getAuthState = (state) => state.auth;

// GET TRANSACTION GROWTH SAGA
export function* getTransactionGrowthSaga(action) {
  try {
    const {
      user: { clientId },
    } = yield select(getAuthState);
    const body = {
      clientId,
      ...action.data,
    };

    let data = {}
    let clientID = localStorage.getItem("client_id")
    if (clientID && parseFloat(clientID) === body.clientId) {
      let specificLoadedData = localStorage.getItem(`transaction-growth-${body.span}-${body.period}`)
      let sumaryData = localStorage.getItem(`transaction-summary-${body.span}-${body.period}`)
      let sumaryDayData = localStorage.getItem(`transaction-day-summary-${body.span}-${body.period}`)
      let monthlyTransaction = localStorage.getItem(`monthly-trans-${body.span}-${body.period}`)
      
      if (specificLoadedData && sumaryData && sumaryDayData && monthlyTransaction) {
        data = { 
          data: JSON.parse(specificLoadedData), 
          overview: JSON.parse(sumaryData), 
          summary: JSON.parse(sumaryDayData),
          monthlyTransaction: JSON.parse(monthlyTransaction),
        }
      } else {
        const response = yield call(TransactionAPI.getTransactionGrowth, decamelizeKeys(body));
        data = transactionGrowthData(response, body)
      }
    } else {
      const response = yield call(TransactionAPI.getTransactionGrowth, decamelizeKeys(body));
      data = transactionGrowthData(response, body)
    }
    yield put(getTransactionGrowthSuccess(camelizeKeys({ data: data.data, daySummary: data.summary, monthlyTransaction: data.monthlyTransaction })));
    yield put(getTransactionSummarySuccess(camelizeKeys(data.overview)));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(getTransactionGrowthFailure(uploadDataMessage('Transactions')));
  }
}

export function* watchGetTransactionGrowthSaga() {
  yield takeEvery(GET_TRANSACTION_GROWTH, getTransactionGrowthSaga);
}

const transactionGrowthData = (response, body) => {
  if (response.status === 200) {
    let data = response.data;
    localStorage.setItem("client_id", JSON.stringify(body.clientId))
    localStorage.setItem(`transaction-growth-${body.span}-${body.period}`, JSON.stringify(data.data))
    localStorage.setItem(`monthly-trans-${body.span}-${body.period}`, JSON.stringify(data.monthly_summary))
    localStorage.setItem(`transaction-summary-${body.span}-${body.period}`, JSON.stringify(data.overview))
    localStorage.setItem(`transaction-day-summary-${body.span}-${body.period}`, JSON.stringify(data.day_summary))
    return { data: data.data, overview: data.overview, summary: data.day_summary, monthlyTransaction: data.monthly_summary };
  } else {
    return { data: [], overview: {} };
  }
}