import {
  GET_EXPENSE_SUMMARY,
  GET_EXPENSE_SUMMARY_FAILURE,
  GET_EXPENSE_SUMMARY_SUCCESS,
  DELETE_RECURRING_EXPENSE,
  GET_RECURRING_EXPENSE_SUMMARY,
  GET_RECURRING_EXPENSE_SUMMARY_FAILURE,
  GET_RECURRING_EXPENSE_SUMMARY_SUCCESS,
  CREATE_RECURRING_EXPENSE_SUMMARY,
  CREATE_RECURRING_EXPENSE_SUMMARY_FAILURE
} from './types';

const initialState = {
  expenseData: [],
  expenseOverview: {},
  recurringExpenseData: [],
  isLoading: true,
  error: null,
};

const createRecurrentExpenseSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_RECURRING_EXPENSE_SUMMARY:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CREATE_RECURRING_EXPENSE_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const getRecurrentExpenseSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECURRING_EXPENSE_SUMMARY:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_RECURRING_EXPENSE_SUMMARY_SUCCESS:
      return {
        ...state,
        recurringExpenseData: action.response.recurringExpenseData,
        isLoading: false,
        error: null,
      };
    case GET_RECURRING_EXPENSE_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return createRecurrentExpenseSummaryReducer(state, action);;
  }
};

const getExpenseSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPENSE_SUMMARY:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_EXPENSE_SUMMARY_SUCCESS:
      return {
        ...state,
        expenseData: action.response.data,
        expenseOverview: action.response.overview,
        isLoading: false,
        error: null,
      };
    case GET_EXPENSE_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case DELETE_RECURRING_EXPENSE:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    default:
      return getRecurrentExpenseSummaryReducer(state, action);
  }
};

export default getExpenseSummaryReducer;
