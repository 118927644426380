import {
  GET_VIEW_SUMMARY,
  GET_VIEW_SUMMARY_FAILURE,
  GET_VIEW_SUMMARY_SUCCESS,
} from './types';


// Get view summary actions  sales, transactions, or customers
export const getViewSummary = (data) => ({
  type: GET_VIEW_SUMMARY,
  data,
});

export const getViewSummarySuccess = (response) => ({
  type: GET_VIEW_SUMMARY_SUCCESS,
  response,
});

export const getViewSummaryFailure = (error) => ({
  type: GET_VIEW_SUMMARY_FAILURE,
  error,
});
