import {
  GET_REVENUE_GROWTH, GET_REVENUE_GROWTH_FAILURE,
  GET_REVENUE_GROWTH_SUCCESS, GET_REVENUE_SUMMARY, GET_REVENUE_SUMMARY_FAILURE,
  GET_REVENUE_SUMMARY_SUCCESS,
} from './types';

const initialState = {
  summary: {},
  growth: {data: [], daySummary: {}},
  isLoading: true,
  error: null,
};


const getRevenueGrowthReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_REVENUE_GROWTH:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case GET_REVENUE_GROWTH_SUCCESS:
    return {
      ...state,
      growth: action.response,
      isLoading: false,
      error: null,
    };
  case GET_REVENUE_GROWTH_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default: return state;
  }
};

const getRevenueSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_REVENUE_SUMMARY:
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  case GET_REVENUE_SUMMARY_SUCCESS:
    return {
      ...state,
      summary: action.response,
      isLoading: false,
      error: null,
    };
  case GET_REVENUE_SUMMARY_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default: return getRevenueGrowthReducer(state, action);
  }
};

export default getRevenueSummaryReducer;
