import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class UserAPI {
  static newCreateUser(userData) {
    delete axios.defaults.headers.common.Authorization;
    return axios.post(`${baseUrl}/user`, userData);
  }

  static async newLoginUser(userData) {
    return axios.post(`${baseUrl}/auth`, userData);
  }

  static getUser(userId) {
    return axios.get(`${baseUrl}/user`, { params: { user_id: userId.user_id } });
  }

  static deleteUser(userId) {
    return axios.delete(`${baseUrl}/user`, { params: { user_id: userId.user_id } });
  }

  static updateUser(userData) {
    return axios.put(`${baseUrl}/user`, userData);
  }
}

export default UserAPI;
