
import {
  GET_REVENUE_GROWTH, GET_REVENUE_GROWTH_FAILURE,
  GET_REVENUE_GROWTH_SUCCESS, GET_REVENUE_SUMMARY, GET_REVENUE_SUMMARY_FAILURE,
  GET_REVENUE_SUMMARY_SUCCESS, 
} from './types';

// Get Revenue Growth actions
export const getRevenueGrowth = (data) => ({
  type: GET_REVENUE_GROWTH,
  data,
});

export const getRevenueGrowthSuccess = (response) => ({
  type: GET_REVENUE_GROWTH_SUCCESS,
  response,
});

export const getRevenueGrowthFailure = (error) => ({
  type: GET_REVENUE_GROWTH_FAILURE,
  error,
});

// Get Revenue Summary actions
export const getRevenueSummary = (data) => ({
  type: GET_REVENUE_SUMMARY,
  data,
});

export const getRevenueSummarySuccess = (response) => ({
  type: GET_REVENUE_SUMMARY_SUCCESS,
  response,
});

export const getRevenueSummaryFailure = (error) => ({
  type: GET_REVENUE_SUMMARY_FAILURE,
  error,
});
