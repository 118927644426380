import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class fileAPI {
  static async uploadFile(csvData) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios.post(`${baseUrl}/file`, csvData, config);
  }

  static async previewCSV(data) {
    return axios.get(`${baseUrl}/csv`, { params: data });
  }

  static async uploadToTable(data) {
    return axios.post(`${baseUrl}/csv`, data);
  }
}

export default fileAPI;
