import {
  GET_VIEW_SUMMARY,
  GET_VIEW_SUMMARY_FAILURE,
  GET_VIEW_SUMMARY_SUCCESS,
} from './types';

const initialState = {
  summary: [],
  newMetricsData: [],
  overview: {},
  newMetricsOverview: {},
  isLoading: false,
  error: null,
};

const getViewSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_VIEW_SUMMARY:
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  case GET_VIEW_SUMMARY_SUCCESS:
    return {
      ...state,
      summary: action.response.data,
      overview: action.response.overview,
      newMetricsData: action.response.newMetricsData,
      newMetricsOverview: action.response.newMetricsOverview,
      isLoading: false,
      error: null,
    };
  case GET_VIEW_SUMMARY_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  default:
    return state;
  }
};

export default getViewSummaryReducer;
