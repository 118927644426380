import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class ProfitAPI {
  static async getProfitSummary(data) {
    return axios.get(`${baseUrl}/analytics/profit`, { params: { ...data } });
  }
  static async postProfitDetails(data) {
    return axios.post(`${baseUrl}/analytics/profit`, { data: { ...data } });
  }
}

export default ProfitAPI;