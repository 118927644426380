
import {
  GET_TRANSACTION_GROWTH,
  GET_TRANSACTION_GROWTH_FAILURE,
  GET_TRANSACTION_GROWTH_SUCCESS,
  GET_TRANSACTION_SUMMARY,
  GET_TRANSACTION_SUMMARY_FAILURE,
  GET_TRANSACTION_SUMMARY_SUCCESS,
} from './types';

// Get transaction growth
export const getTransactionGrowth = (data) => ({
  type: GET_TRANSACTION_GROWTH,
  data,
});

export const getTransactionGrowthSuccess = (response) => ({
  type: GET_TRANSACTION_GROWTH_SUCCESS,
  response,
});

export const getTransactionGrowthFailure = (error) => ({
  type: GET_TRANSACTION_GROWTH_FAILURE,
  error,
});

// Get Transaction Summary actions
export const getTransactionSummary = (data) => ({
  type: GET_TRANSACTION_SUMMARY,
  data,
});

export const getTransactionSummarySuccess = (response) => ({
  type: GET_TRANSACTION_SUMMARY_SUCCESS,
  response,
});

export const getTransactionSummaryFailure = (error) => ({
  type: GET_TRANSACTION_SUMMARY_FAILURE,
  error,
});
