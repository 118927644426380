import {
    CREATE_NEW_METRICS,
    CREATE_NEW_METRICS_SUCCESS,
    CREATE_NEW_METRICS_FAILURE,
} from './types';

const initialState = {
    isLoading: false,
    error: null,
    message: null
};

const createNewMetricsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_NEW_METRICS:
            return {
                ...state,
                isLoading: true,
                error: null,
                message: null
            };
        case CREATE_NEW_METRICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.data,
                error: null
            };
        case CREATE_NEW_METRICS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.data,
            };
        default:
            return state;
    }
};

export default createNewMetricsReducer;