import {
  EDIT_PROFILE,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_SUCCESS, GET_PROFILE, GET_PROFILE_FAILURE, GET_PROFILE_SUCCESS, 
} from './types';

// Get user profile
export const getUserProfile = () => ({
  type: GET_PROFILE,
});

export const getUserProfileSuccess = (response) => ({
  type: GET_PROFILE_SUCCESS,
  response,
});

export const getUserProfileFailure = (error) => ({
  type: GET_PROFILE_FAILURE,
  error,
});

// Edit user profile
export const editUserProfile = (data) => ({
  type: EDIT_PROFILE,
  data,
});

export const editUserProfileSuccess = (response) => ({
  type: EDIT_PROFILE_SUCCESS,
  response,
});

export const editUserProfileFailure = (error) => ({
  type: EDIT_PROFILE_FAILURE,
  error,
});
