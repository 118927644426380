import {
  EDIT_CLIENT,
  EDIT_CLIENT_FAILURE,
  EDIT_CLIENT_SUCCESS,
  REGISTER_CLIENT,
  REGISTER_CLIENT_FAILURE,
  REGISTER_CLIENT_SUCCESS,
  SELECT_CLIENT,
  SELECT_CLIENT_FAILURE,
  SELECT_CLIENT_SUCCESS,
} from './types';

// Register client actions
export const registerClient = (data) => ({
  type: REGISTER_CLIENT,
  data,
});

export const registerClientSuccess = (response) => ({
  type: REGISTER_CLIENT_SUCCESS,
  response,
});

export const registerClientFailure = (error) => ({
  type: REGISTER_CLIENT_FAILURE,
  error,
});

// Edit client
export const updateClient = (data) => ({
  type: EDIT_CLIENT,
  data,
});

export const updateClientSuccess = (response) => ({
  type: EDIT_CLIENT_SUCCESS,
  response,
});

export const updateClientFailure = (error) => ({
  type: EDIT_CLIENT_FAILURE,
  error,
});

// select client
export const selectClient = (data) => ({
  type: SELECT_CLIENT,
  data,
});

export const selectClientSuccess = (response) => ({
  type: SELECT_CLIENT_SUCCESS,
  response,
});

export const selectClientFailure = (error) => ({
  type: SELECT_CLIENT_FAILURE,
  error,
});
