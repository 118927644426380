import SummaryAPi from '../services/summaryAPI'

export const frequencyOptions = [
    { label: "Yearly", value: "yearly" },
    { label: "Monthly", value: "monthly" },
    { label: "Weekly", value: "weekly" },
    { label: "Daily", value: "daily" },
]

export const dayOptions = [
    { label: "Sunday", value: "sunday" },
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday", },
    { label: "Saturday", value: "saturday" }
]

export const dayOfMonthOption = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20"},
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" }
]
export const monthOptions = [
    { label: "January", value: "january" },
    { label: "February", value: "february" },
    { label: "March", value: "march" },
    { label: "April", value: "april" },
    { label: "May", value: "may" },
    { label: "June", value: "june" },
    { label: "July", value: "july" },
    { label: "August", value: "august" },
    { label: "September", value: "september" },
    { label: "October", value: "october" },
    { label: "November", value: "november" },
    { label: "December", value: "december" }
]
export const monthToNumber = (identifier) => {
    const values = {
        "january": 1,
        "february": 2,
        "march": 3,
        "april": 4,
        "may": 5,
        "june": 6,
        "july": 7,
        "august": 8,
        "september": 9,
        "october": 10,
        "november": 11,
        "december": 12
    }
    return values[identifier]
}
export const weekToNumber = (identifier) => {
    const values = {
        "sunday": 7,
        "monday": 1,
        "tuesday": 2,
        "wednesday": 3,
        "thursday": 4,
        "friday": 5,
        "saturday": 6
    }
    return values[identifier]
}
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const getSingleDateFormatted = (date) => {
    const day = new Date(date);
    return (day.getDate() + " - " + monthNames[day.getMonth()] + " - " + day.getFullYear())
}

export const getMonthInFormat = (xData) => {
    let xdat = []

    if (xData) {
        xData.forEach(det => {
            const day = new Date(det);
            xdat.push(day.getDate() + " - " + monthNames[day.getMonth()] + " - " + day.getFullYear());
        })
        return xdat;
    } else {
        return xData;
    }
}

export const checkDataLoaded = async (data) => {
    try {
        let response = await SummaryAPi.getViewSummary(data)
        return response;
    } catch (e) {
        console.log('error ==>', e)
    }
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const xAxisData = (data) => {
    let validAxisData = []
    if (data && data.length > 0) {
        const xData = data.map((growth) => growth.date);
        validAxisData = getMonthInFormat(xData)
    }
    return validAxisData;
}

export const PERIODS = {
    '30_DAYS': '30days',
    '90_DAYS': '90days',
    '180_DAYS': '180days',
    '365_DAYS': '365days',
};

export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const uploadDataMessage = (name) => {
    return `Kindly upload the ${name} Data for proper analysis`
}