import axios from 'axios';

import resolveBaseUrl from '.';

const baseUrl = resolveBaseUrl();

class TransactionAPI {
  static async getTransactionGrowth(data) {
    return axios.get(`${baseUrl}/analytics/transaction/growth`, { params: { ...data } });
  }

  static async getTransactionSummary(data) {
    return axios.get(`${baseUrl}/analytics/transaction/summary`, { params: { ...data } });
  }
}

export default TransactionAPI;
